// No nesting! - colors declarations can only be one level deep
// No variables - you can't reference the colors by var name (e.g. 'primary') within this file

const base = {
  primary: '#105041', // forest
  secondary: '#8BF142', // kelly
  background: '#F1F1F1',
  white: '#F2F3F3',
  greenPattern: '#F2F3F3',
  grey: '#C7C7C7',
  black: '#000000',
  darkForrest: '#0F4337',

  // Text Styles
  lightText: '#FFFFFF',
  divider: '#00000099' // rgba(0,0,0,0.6)
};

const textColors = {
  text: base.primary, // darkText
  heading: base.primary // darkText
};

export const colors = {
  // defaults
  ...base,
  ...textColors
};
