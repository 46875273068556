import React from 'react';

import { Box } from '@thepuzzlers/pieces';

export const MenuOpen = ({ sx, ...props }) => {
  return (
    <Box
      as="svg"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: '100%',
        ...sx
      }}
      {...props}>
      <rect x="9" y="10" width="6" height="2" fill="#F2F3F3" />
      <rect x="18" width="6" height="2" fill="#F2F3F3" />
      <rect x="18" y="20" width="6" height="2" fill="#F2F3F3" />
      <rect width="6" height="2" fill="#F2F3F3" />
      <rect y="20" width="6" height="2" fill="#F2F3F3" />
    </Box>
  );
};
