import { useStaticQuery, graphql } from 'gatsby';
import { useKisSectionData } from './helper';

export const useKisLeistungenPageData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: allKisLeistungenPage {
        nodes {
          entry {
            examples {
              caption {
                html
              }
              contact_button_text
              contact_caption {
                html
              }
              contact_headline {
                html
              }
              headline {
                html
              }
              image_descriptions {
                data {
                  description_list {
                    description
                  }
                  title {
                    html
                  }
                }
              }
              note {
                html
              }
            }
            extras {
              caption {
                html
              }
              extras {
                text
              }
              headline {
                html
              }
            }
            furniture {
              caption {
                html
              }
              headline {
                html
              }
            }
            header {
              description {
                html
              }
              headline {
                html
              }
              wheel_number
              wheel_caption {
                html
              }
            }
            steps {
              headline {
                html
              }
              step_list {
                title
                description
              }
            }
            warranty {
              description {
                html
              }
              warranty_list {
                text
              }
              headline {
                html
              }
            }
          }
        }
      }
    }
  `);

  return useKisSectionData(data);
};
