import { useStaticQuery, graphql } from 'gatsby';
import { useKisSectionData } from './helper';

export const useKisContactPageData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: allKisContactPage {
        nodes {
          entry {
            header {
              headline {
                html
              }
              image_caption
            }
            form {
              button_text
              description
              headline
              message_error_description {
                html
              }
              message_error_title
              message_success_description
              message_success_title
              placeholder_email
              placeholder_name
              placeholder_phone
              placeholder_request
              privacy_disclaimer {
                html
              }
            }
          }
        }
      }
    }
  `);

  return useKisSectionData(data);
};
