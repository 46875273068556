import React from 'react';

// External Components
import { Box, Paragraph } from '@thepuzzlers/pieces';

// Local Components
import { DividerLine } from './DividerLine';

export const ShortInformationBlock = ({
  textWrapperClass,
  label,
  text,
  isLight = false,
  animatedLine = false,
  sx
}) => {
  return (
    <Box sx={{ alignSelf: 'flex-end', ...sx }}>
      <Box className={textWrapperClass}>
        <Paragraph
          sx={{
            color: isLight ? 'white' : 'text',
            fontFamily: 'body.bold',
            fontSize: [
              '1.2rem',
              '1.6rem',
              '1.2rem',
              '1.2rem',
              '1.4rem',
              '1.3rem'
            ],
            fontFeatureSettings: "'ss05' on, 'ss08' on",
            lineHeight: 1
          }}>
          {label}
        </Paragraph>
        <Paragraph
          sx={{
            color: isLight ? 'white' : 'text',
            fontFamily: 'body.normal',
            fontSize: [
              '1.4rem',
              '1.8rem',
              '1.4rem',
              '1.4rem',
              '1.6rem',
              '1.6rem'
            ],
            fontFeatureSettings: "'ss05' on, 'ss08' on",
            mt: ['0.6rem', '0.6rem', '0.6rem', '0.4rem', '0.5rem', '0.5rem'],
            lineHeight: [1.4, 1.4, 1.4, 1.4, 1.2, 1.2],
            position: 'relative'
          }}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </Box>
      <DividerLine
        animated={animatedLine}
        shortLine
        isLight={isLight}
        sx={{
          mt: ['0.7rem', '1rem', '0.6rem', '0.8rem', '0.7rem', '0.8rem']
        }}
      />
    </Box>
  );
};
