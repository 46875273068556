import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const MenuClosed = ({ sx, ...props }) => {
  return (
    <Box
      as="svg"
      viewBox="0 0 28 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: '100%',
        color: 'primary',
        ...sx
      }}
      {...props}>
      <rect width="10" height="2" fill="currentColor" />
      <rect y="10" width="28" height="2" fill="currentColor" />
      <rect y="20" width="10" height="2" fill="currentColor" />
      <rect x="18" width="10" height="2" fill="currentColor" />
      <rect x="18" y="20" width="10" height="2" fill="currentColor" />
    </Box>
  );
};
