const buttonDefault = {
  cursor: 'pointer'
};

const linkDefault = {
  cursor: 'pointer',
  display: 'block',
  width: 'max-content',
  '& > a': {
    position: 'relative',
    zIndex: 2
  }
};

const primaryButtonBase = ({ color, colorOnHover, backgroundOnHover }) => ({
  ...buttonDefault,
  color,
  backgroundColor: 'transparent',
  border: ({ colors }) => `solid ${colors[color]}`,
  borderWidth: 'clamp(2px, 0.2rem, 3px)',
  whiteSpace: 'nowrap',
  position: 'relative',
  borderRadius: 0,

  // Hover animation based on this code pen:
  // https://codepen.io/thelaazyguy/pen/brryVq
  '&:hover, &:focus': {
    color: colorOnHover,
    transition: 'all .25s'
  },
  // Hover Background
  '&:after': {
    backgroundColor: backgroundOnHover,
    position: 'absolute',
    content: "''",
    bottom: '-0.1rem',
    left: '-0.1rem',
    width: 'calc(100% + 0.2rem)',
    height: '0%',
    transition: 'all .25s'
  },
  '&:hover:after, &:focus:after': {
    height: 'calc(100% + 0.2rem)'
  },
  // Text Container
  '&>span': {
    position: 'relative',
    zIndex: 2
  }
});

const primarySmallBase = {
  padding: [
    '1.3rem 2.4rem 1.2rem 2.4rem',
    '1.5rem 3.2rem 1.4rem 3.2rem',
    '0.8rem 3.4rem 0.7rem 3.4rem',
    '0.8rem 3.4rem 0.7rem 3.4rem',
    '1.2rem 3.2rem 1rem 3.2rem',
    '1.3rem 3.2rem 1rem 3.2rem'
  ],
  lineHeight: 1,
  fontSize: ['2rem', '2rem', '1.6rem', '1.6rem', '1.8rem', '1.6rem'],
  letterSpacing: '0.02em',
  fontFeatureSettings: '"ss02" on',
  fontFamily: 'body.bold'
};

const hyperlinkDefault = {
  ...linkDefault,
  fontFamily: 'primary.bold',
  fontFeatureSettings:
    "'ss02' on, 'salt' on, 'ss01' on, 'ss03' on, 'ss04' on, 'ss05' on, 'ss08' on, 'ss14' on, 'calt' off",
  letterSpacing: '0.02em',
  lineHeight: 1,
  // Need to do this to make whole button is clickable, because NavigationLink from the puzzlers has span as the outer tag and a tag inside it
  '& a': {
    p: '1.2rem 3rem 1rem 3rem',
    display: 'block'
  }
};

const links = {
  legal: {
    ...linkDefault
  },
  contact: {
    ...linkDefault
  },
  hyperlink: {
    primary: {
      ...hyperlinkDefault,
      ...primaryButtonBase({
        color: 'primary',
        colorOnHover: 'secondary',
        backgroundOnHover: 'primary'
      }),
      fontSize: ['1.6rem', '2rem', '2rem', '1.6rem', '2rem', '2rem']
    },
    secondary: {
      ...hyperlinkDefault,
      ...primaryButtonBase({
        color: 'white',
        colorOnHover: 'primary',
        backgroundOnHover: 'white'
      }),
      fontSize: ['1.8rem', '2rem', '1.8rem', '1.8rem', '1.8rem', '2.2rem']
    }
  },
  navigation: {
    ...linkDefault
  },
  footer: {
    ...linkDefault,
    color: 'white',
    fontFamily: 'body.normal',
    fontFeatureSettings: "'ss05' on, 'ss08' on, 'ss14' on, 'calt' off",
    fontSize: ['1.4rem', '1.6rem', '1.4rem', '1.4rem', '1.4rem', '1.4rem'],
    lineHeight: 1
  },
  footerSublinks: {
    ...linkDefault,
    color: 'white',
    fontFamily: 'body.normal',
    fontSize: ['1.5rem', '1.6rem', '1.5rem', '1.5rem', '1.5rem', '1.4rem'],
    lineHeight: 1
  },
  clear: {
    ...linkDefault,
    p: 0,
    bg: 'transparent'
  }
};

const buttons = {
  primary: {
    ...primaryButtonBase({
      color: 'primary',
      colorOnHover: 'secondary',
      backgroundOnHover: 'primary'
    }),
    padding: [
      '1.3rem 2.4rem 1.1rem 2.4rem',
      '1.8rem 3.2rem 1.6rem 3.2rem',
      '1.3rem 3.2rem 1.1rem 3.2rem',
      '1.2rem 2.4rem 1rem 2.4rem',
      '1.3rem 3.2rem 1.1rem 3.2rem'
    ],
    // variant: 'typography.modulButton.bold',
    fontFamily: 'primary.bold',
    lineHeight: 1,
    letterSpacing: '0.02em',
    fontSize: ['2rem', '2rem', '2.4rem', '2rem', '2rem', '2.2rem']
  },
  clear: {
    ...buttonDefault,
    p: 0,
    bg: 'transparent'
  },
  primaryLight: {
    ...primaryButtonBase({
      color: 'white',
      colorOnHover: 'darkText',
      backgroundOnHover: 'white'
    }),
    padding: [
      '1.3rem 2.4rem',
      '1.8rem 3.2rem',
      '1.3rem 3.2rem',
      '1.2rem 2.4rem',
      '1.3rem 3.2rem'
    ]
  },
  primarySmall: {
    ...primarySmallBase,
    ...primaryButtonBase({
      color: 'primary',
      colorOnHover: 'secondary',
      backgroundOnHover: 'primary'
    })
  },
  primaryLightSmall: {
    ...primarySmallBase,
    ...primaryButtonBase({
      color: 'white',
      colorOnHover: 'text',
      backgroundOnHover: 'white'
    })
  }
};

const cards = {};

export { links, buttons, cards };
