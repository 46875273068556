import React from 'react';
import { gsap, CustomEase } from 'gsap/all';

// pieces
import {
  Section,
  Heading,
  Paragraph,
  Box,
  FlexWrapper
} from '@thepuzzlers/pieces';

// components
import { convertApiHtmlText } from 'graphqlHooks/helper';
// Helper function
import { BorderedImage } from 'components';
import { getNumbersArray } from './helper';

export const HeaderWithNumber = ({
  staticData: { image },
  data: { headline, description, wheel_number, wheel_caption }
}) => {
  const headingRef = React.useRef();
  const numberCaptionRef = React.useRef();
  const numbersWrapperRef = React.useRef();
  const singleNumberRef = React.useRef();

  React.useLayoutEffect(() => {
    gsap.set(headingRef.current, {
      y: 50
    });
    gsap.set(numberCaptionRef.current, {
      y: 50
    });
  }, []);

  React.useEffect(() => {
    const customEase = CustomEase.create(
      'custom',
      'M0,0 C0.256,0.022 0.248,0.13 0.44,0.612 0.509,0.785 0.504,1 1,1 '
    );
    // Fade in Headline on page load
    const timeline = gsap
      .timeline({ delay: 0.5 })
      .to(headingRef.current, {
        y: 0,
        autoAlpha: 1,
        duration: 0.6,
        ease: 'power1'
      })
      .fromTo(
        numbersWrapperRef.current,
        {
          y: 0
        },
        {
          y:
            numbersWrapperRef.current.offsetHeight -
            singleNumberRef.current.offsetHeight,

          duration: '1.5',
          ease: customEase
        },
        '<'
      )
      .to(
        numberCaptionRef.current,
        {
          y: 0,
          autoAlpha: 1,
          duration: 0.6,
          ease: 'power1'
        },
        '-=0.4'
      );
    const killAnimation = () => {
      timeline.pause(0).kill(true);
    };

    return killAnimation;
  });
  return (
    <Section as="header">
      <Headline headline={headline} headingRef={headingRef} />
      <Number
        caption={wheel_caption}
        refs={{ numbersWrapperRef, singleNumberRef, numberCaptionRef }}
        numbersArray={getNumbersArray(wheel_number)}
      />
      <ImageWrapper image={image} />
      <Text text={description} />
    </Section>
  );
};

const Headline = ({ headline, headingRef }) => (
  <Heading
    ref={headingRef}
    as="h1"
    dangerouslySetInnerHTML={{
      __html: convertApiHtmlText(headline.html)
    }}
    sx={{
      gridColumn: [
        '1/span 12',
        '1/span 11',
        '2/span 20',
        '1/span 21',
        '2/span 14',
        '2/span 13'
      ],
      fontFamily: 'primary.normal',
      lineHeight: 1.5,
      fontSize: ['2.8rem', '3.8rem', '4rem', '2.8rem', '3.6rem', '4.2rem'],
      mt: ['3.7rem', '4rem', '10.5rem', '3.9rem', ' 8.2rem', '9.2rem'],
      '& > span': {
        fontFamily: 'primary.bold'
      },
      visibility: 'hidden'
    }}
  />
);

const Number = ({ caption, numbersArray, refs }) => (
  <Box
    sx={{
      gridColumn: [
        '5/span 8',
        '6/span 7',
        '12/span 12',
        '15/span 8',
        '17/span 7',
        '17/span 7'
      ],
      gridRow: [null, null, null, null, 2, 2, 2],
      mt: [null, null, null, null, null, '-30%']
    }}>
    <NumberWheel refs={refs} numbersArray={numbersArray} />
    <Paragraph
      ref={refs.numberCaptionRef}
      sx={{
        lineHeight: 1.5,
        fontFamily: 'body.normal',
        fontSize: ['1.6rem', '1.8rem', '1.8rem', '1.6rem', '1.8rem', '1.8rem'],
        '& > span': {
          fontFamily: 'body.bold'
        },
        visibility: 'hidden'
      }}
      dangerouslySetInnerHTML={{
        __html: convertApiHtmlText(caption.html)
      }}
    />
  </Box>
);

const NumberWheel = ({
  refs: { numbersWrapperRef, singleNumberRef },
  numbersArray
}) => {
  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        mt: ['6.1rem', '9.3rem', '22.2rem', '7rem', '0.5rem', 0]
      }}>
      <FlexWrapper
        ref={numbersWrapperRef}
        sx={{ flexDirection: 'column', position: 'absolute', bottom: 0 }}>
        {numbersArray.reverse().map((number) => (
          <WheelNumber key={number} number={number} />
        ))}
      </FlexWrapper>
      {/* Invisible - to set the height */}
      <WheelNumber
        number={24}
        sx={{ visibility: 'hidden' }}
        singleNumberRef={singleNumberRef}
      />
    </Box>
  );
};

const WheelNumber = ({ number, sx, singleNumberRef }) => {
  return (
    <Paragraph
      ref={singleNumberRef}
      sx={{
        fontFamily: 'primary.bold',
        lineHeight: 1,
        fontSize: ['16rem', '24rem', '28rem', '16rem', '23rem', '30rem'],
        color: 'secondary',
        ...sx
      }}>
      {number}
    </Paragraph>
  );
};

const ImageWrapper = ({ image }) => (
  <BorderedImage
    yOffset={11}
    xOffset={11}
    image={image.src}
    imageAlt={image.alt}
    sx={{
      gridColumn: [
        '1/span 10',
        '1/span 9',
        '2/span 15',
        '1/span 8',
        '1/span 10',
        '1/span 9'
      ],
      mt: ['21.9rem', '31.7rem', '25.5rem', '8.1rem', '21.9rem', '16.7rem'],
      gridRow: [null, null, null, null, 3, 3]
    }}
    imageSx={{ boxShadow: 'elevation1000' }}
  />
);

const Text = ({ text }) => (
  <Paragraph
    dangerouslySetInnerHTML={{
      __html: convertApiHtmlText(text.html)
    }}
    sx={{
      gridColumn: [
        '1/span 12',
        '2/span 10',
        '6/span 19',
        '11/span 12',
        '14/span 11',
        '13/span 11'
      ],
      mt: ['7.5rem', '12.5rem', '11.8rem', 0, 0, 0],
      mb: [null, null, null, '-11.2rem', '-8.8rem', '-5.4rem'],
      alignSelf: 'end',
      fontFamily: 'primary.normal',
      lineHeight: 1.6,
      fontSize: ['2rem', '2.4rem', '2.8rem', '2rem', '2.6rem', '3rem'],
      '& > span': {
        fontFamily: 'primary.bold'
      }
    }}
  />
);
