import React from 'react';

// External Components
import { GridWrapper } from '@thepuzzlers/pieces';
import { useKisBanner } from 'graphqlHooks';
import { Paragraph } from '@thepuzzlers/pieces/dist/thepuzzlers-pieces.cjs.prod';
import { BannerDecoration } from './svgs';
import { ImageContainer } from './ImageContainer';

export const Banner = () => {
  const { image, text } = useKisBanner();

  return (
    <GridWrapper
      sx={{
        bg: 'primary',
        py: ['1.4rem', '1.4rem', '1.4rem', '1.4rem', '1.2rem', '1.2rem'],
        position: 'relative',
        overflow: 'hidden'
      }}>
      <Text text={text} />
      <Awards data={image} />
      <Decoration />
    </GridWrapper>
  );
};

const Text = ({ text }) => {
  return (
    <Paragraph
      sx={{
        gridColumn: [
          '1/ span 7',
          '1/ span 7',
          '1/ span 20',
          '1/ span 20',
          '1/ span 20',
          '2/ span 19'
        ],
        color: 'white',
        fontFamily: 'body.bold',
        fontSize: ['1.1rem', '1.2rem', '1.4rem', '1.2rem', '1.4rem', '1.5rem'],
        lineHeight: 1.2,
        zIndex: 1,
        position: 'relative',
        alignSelf: 'center'
      }}>
      {text}
    </Paragraph>
  );
};

const Decoration = () => {
  return (
    <BannerDecoration
      sx={{
        position: 'absolute',
        bottom: [0, '-1rem', '-1.5rem', '-2rem', '-4rem', '-6rem'],
        left: 0,
        width: ['19rem', '24rem', '25rem', '26rem', '32rem', '38.6rem']
      }}
    />
  );
};

const Awards = ({ data: { image, alt } }) => {
  return (
    <ImageContainer
      src={image}
      imageAlt={alt}
      sx={{
        gridColumn: ['9/13', '9/13', '21/25', '21/25', '21/25', '21/ span 3'],
        justifySelf: 'end',
        width: ['8.5rem', '9rem', '9.8rem', '8rem', '10rem', '11.8rem'],
        alignSelf: 'center'
      }}
    />
  );
};
