import { useStaticQuery, graphql } from 'gatsby';
import { useKisSectionData } from './helper';

export const useKisProduktdetailsseitePageData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: allKisProductDetailPagePage {
        nodes {
          entry {
            header {
              living_space_label
              price_label
              production_time_label
              turnkey_label
              awward_image {
                alt
                image {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
                  }
                }
              }
            }
            floor_plan_details {
              title {
                html
              }
              modules_label
              living_area_label
              floor_space_label
              length_label
              width_label
              list_caption
              list_title
            }
            personalized_home {
              title {
                html
              }
              richtextdescription {
                html
              }
              image_caption
            }
            services {
              richtexttitle {
                html
              }
              services {
                data {
                  service {
                    name
                    description
                  }
                  title
                }
              }
              extra_services {
                data {
                  description
                  title
                  service {
                    name
                    description
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return useKisSectionData(data);
};
