const shadows = {
  elevation100: '0px 4px 12px rgba(0, 0, 0, 0.15)',
  elevation700: '0px 24px 32px rgba(0, 0, 0, 0.25)',
  elevation1000: '0px 32px 48px rgba(0, 0, 0, 0.35)',
  shadow400: '0px 16px 48px rgba(0, 0, 0, 0.18)'
};

const radii = {
  card: '0.4rem'
};

export { shadows, radii };
