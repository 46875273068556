import React from 'react';
import { Heading, Box, GridWrapper } from '@thepuzzlers/pieces';
import { ImageContainer } from '../../components/ImageContainer';
import { checkIsFirstData, checkIsLastData } from 'utils';

export const ImageWithListWrapper = ({
  imageData,
  children,
  index,
  totalData,
  wrapperSx = {},
  textSx = {},
  imageGridColumn = null
}) => {
  const isFirstData = checkIsFirstData(index, totalData);
  const isLastData = checkIsLastData(index, totalData);
  return (
    <GridWrapper
      sx={{
        alignContent: [null, null, null, 'end', null, null],
        marginBottom: [
          isFirstData ? '15.8rem' : isLastData ? 0 : '23rem',
          !isLastData ? '15rem' : 0,
          !isLastData ? '19.2rem' : 0,
          !isLastData ? '11.4rem' : 0,
          !isLastData ? '19.3rem' : 0,
          !isLastData ? '20rem' : 0
        ],
        ...wrapperSx
      }}
      variant="inside.autoColumns">
      <ImageContainer
        sx={{
          gridColumn: imageGridColumn ?? [
            isFirstData
              ? '1 / span 12'
              : isLastData
              ? '1 / span 11'
              : '1 / span 10', // 375
            isFirstData
              ? '1 / span 10'
              : isLastData
              ? '1 / span 9'
              : '1 / span 8', // 640
            isFirstData
              ? '1 / span 15'
              : isLastData
              ? '1 / span 13'
              : '1 / span 11', // 834
            isFirstData
              ? '1 / span 11'
              : isLastData
              ? '1 / span 10'
              : '13 / span 9', // 812
            isFirstData
              ? '1 / span 11'
              : isLastData
              ? '1 / span 9'
              : '13 / span 8', // 1194
            isFirstData
              ? '1 / span 10'
              : isLastData
              ? '1 / span 9'
              : '12 / span 8' // 1440
          ],
          filter: [
            isFirstData
              ? 'drop-shadow(0rem 3.2rem 4.8rem rgba(0, 0, 0, 0.35))'
              : isLastData
              ? 'drop-shadow(0rem 2.4rem 3.2rem rgba(0, 0, 0, 0.25))'
              : 'drop-shadow(0rem 0.4rem 1.2rem rgba(0, 0, 0, 0.15))', // 375
            isFirstData
              ? 'drop-shadow(0rem 3.2rem 4.8rem rgba(0, 0, 0, 0.35))'
              : isLastData
              ? 'drop-shadow(0rem 2.4rem 3.2rem rgba(0, 0, 0, 0.25))'
              : 'drop-shadow(0rem 0.4rem 1.2rem rgba(0, 0, 0, 0.15))', // 640
            isFirstData
              ? 'drop-shadow(0rem 3.2rem 4.8rem rgba(0, 0, 0, 0.35))'
              : isLastData
              ? 'drop-shadow(0rem 2.4rem 3.2rem rgba(0, 0, 0, 0.25))'
              : 'drop-shadow(0rem 0.4rem 1.2rem rgba(0, 0, 0, 0.15))', //834
            isFirstData
              ? 'drop-shadow(0rem 3.2rem 4.8rem rgba(0, 0, 0, 0.35))'
              : isLastData
              ? 'drop-shadow(0rem 2.4rem 3.2rem rgba(0, 0, 0, 0.25))'
              : 'drop-shadow(0rem 0.4rem 1.2rem rgba(0, 0, 0, 0.15))', //812
            isFirstData
              ? 'drop-shadow(0rem 3.2rem 4.8rem rgba(0, 0, 0, 0.35))'
              : isLastData
              ? 'drop-shadow(0rem 2.4rem 3.2rem rgba(0, 0, 0, 0.25))'
              : 'drop-shadow(0rem 0.4rem 1.2rem rgba(0, 0, 0, 0.15))', //1194
            isFirstData
              ? 'drop-shadow(0rem 3.2rem 4.8rem rgba(0, 0, 0, 0.35))'
              : isLastData
              ? 'drop-shadow(0rem 2.4rem 3.2rem rgba(0, 0, 0, 0.25))'
              : 'drop-shadow(0rem 0.4rem 1.2rem rgba(0, 0, 0, 0.15))' //1440
          ],
          gridRow: [null, null, null, '1 / span 1', null, null],
          marginBottom: ['4rem', '4.8rem', null, 0, null, null]
        }}
        src={imageData.image}
        imageAlt={imageData.alt}
      />
      <Box // Text Box
        sx={{
          alignSelf: [null, null, null, 'end', null, null],
          ...textSx,
          gridRow: [2, 2, 2, 1, 1, 1]
        }}>
        <Heading
          as="h4"
          sx={{
            marginBottom: [
              '2rem',
              '2.4rem',
              '2.4rem',
              '2.4rem',
              '2.4rem',
              '2.4rem'
            ],
            marginTop: [null, null, null, 'auto', null, null],
            '& > span': {
              color: 'secondary'
            },
            lineHeight: [1, 1, 1, 1, 1.6, 1.6],
            fontFamily: 'primary.bold',
            fontSize: ['2.2rem', '2.8rem', '3rem', '2.4rem', '3rem', '3.2rem']
          }}
          dangerouslySetInnerHTML={{ __html: imageData.title }}
        />
        {children}
      </Box>
    </GridWrapper>
  );
};
