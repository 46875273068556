exports.components = {
  "component---node-modules-gatsby-theme-thepuzzlers-legal-pages-src-pages-legal-js": () => import("./../../../node_modules/gatsby-theme-thepuzzlers-legal-pages/src/pages/legal.js" /* webpackChunkName: "component---node-modules-gatsby-theme-thepuzzlers-legal-pages-src-pages-legal-js" */),
  "component---node-modules-gatsby-theme-thepuzzlers-legal-pages-src-pages-privacy-policy-js": () => import("./../../../node_modules/gatsby-theme-thepuzzlers-legal-pages/src/pages/privacy-policy.js" /* webpackChunkName: "component---node-modules-gatsby-theme-thepuzzlers-legal-pages-src-pages-privacy-policy-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-houses-js": () => import("./../../../src/pages/houses.js" /* webpackChunkName: "component---src-pages-houses-js" */),
  "component---src-pages-impressionen-js": () => import("./../../../src/pages/impressionen.js" /* webpackChunkName: "component---src-pages-impressionen-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-leistungen-js": () => import("./../../../src/pages/leistungen.js" /* webpackChunkName: "component---src-pages-leistungen-js" */),
  "component---src-pages-nachhaltigkeit-js": () => import("./../../../src/pages/nachhaltigkeit.js" /* webpackChunkName: "component---src-pages-nachhaltigkeit-js" */),
  "component---src-templates-product-detail-product-detail-js": () => import("./../../../src/templates/productDetail/ProductDetail.js" /* webpackChunkName: "component---src-templates-product-detail-product-detail-js" */)
}

