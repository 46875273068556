import React from 'react';
import { Paragraph, Box } from '@thepuzzlers/pieces';
import { checkIsLastData } from 'utils';

import { ImageWithListWrapper } from './ImageWithListWrapper';
import { Line } from './Line';

export const ImageWithSimpleList = ({
  image,
  data,
  index,
  totalData,
  wrapperSx,
  textSx
}) => (
  <ImageWithListWrapper
    key={`imageWithListData-${index}`}
    imageData={image}
    index={index}
    totalData={totalData}
    wrapperSx={wrapperSx}
    textSx={textSx}>
    {data.descriptionList.map((desc, listIndex, listArr) => {
      return (
        <ListItemWithStripes
          key={`list-item-${listIndex}`}
          text={desc.text}
          index={listIndex}
          isLastItem={checkIsLastData(listIndex, listArr.length)}
        />
      );
    })}
  </ImageWithListWrapper>
);

const ListItemWithStripes = ({ text, isLastItem }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      alignSelf: 'end',
      marginBottom: !isLastItem && ['2rem', '2.4rem', null, 0, null, null],
      marginTop: [null, null, null, '2rem', '2.4rem', '2.4rem']
    }}>
    <Line />
    <Paragraph
      sx={{
        flex: '1',
        lineHeight: 1.25,
        fontFamily: 'body.bold',
        fontSize: ['1.6rem', '1.8rem', '1.8rem', '1.6rem', '1.8rem', '1.8rem']
      }}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  </Box>
);
