import { useStaticQuery, graphql } from 'gatsby';
import { useKisSectionData } from './helper';

export const useKisImpressionenPageData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: allKisImpressionenPagePage {
        nodes {
          entry {
            facts {
              headline {
                html
              }
              caption {
                html
              }
              list {
                description
                title
              }
              number
            }
            header {
              caption {
                html
              }
              description {
                html
              }
              headline {
                html
              }
            }
            services {
              caption {
                html
              }
              description {
                html
              }
              headline {
                html
              }
            }
            sustainability {
              caption {
                html
              }
              description {
                html
              }
              headline {
                html
              }
              list {
                text
              }
            }
          }
        }
      }
    }
  `);

  return useKisSectionData(data);
};
