import { graphql, useStaticQuery } from 'gatsby';

export const useNavigationData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: navigationDataJson {
        pageLinks {
          text
          to
        }
        button {
          text
          to
        }
        pattern {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  `);

  return data;
};
