import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const ListDot = ({ sx, isSmall }) => {
  return (
    <Box
      className="green-dot"
      sx={{
        width: isSmall
          ? '1rem'
          : ['1.6rem', '1.8rem', '1.6rem', '1.6rem', '1.6rem', '1.6rem'],
        height: isSmall
          ? '1rem'
          : ['1.6rem', '1.8rem', '1.6rem', '1.6rem', '1.6rem', '1.6rem'],
        bg: 'secondary',
        borderRadius: '50%',
        ...sx
      }}
    />
  );
};
