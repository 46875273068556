import React from 'react';

import { Box } from '@thepuzzlers/pieces';

export const Line = () => (
  <Box
    className="stripe"
    sx={{
      height: '0.3rem',
      bg: 'secondary',
      width: ['3.2rem', '3.2rem', '3.2rem', '3.2rem', '3.2rem', '3.8rem'],
      marginRight: '1.6rem',
      justifySelf: 'center'
    }}
  />
);
