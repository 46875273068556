import { useStaticQuery, graphql } from 'gatsby';
import { useKisSectionData } from './helper';

export const useKisHouseCategoriesData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: allKisHouseCategories {
        nodes {
          entry {
            title
            description {
              html
            }
            house {
              id
              data {
                name
                modules
                price
                living_area
                length
                footprint
                bedrooms
                short_description {
                  html
                }
                rooms {
                  name
                  size
                }
                width
                image {
                  image {
                    childImageSharp {
                      gatsbyImageData(quality: 100)
                    }
                  }
                  alt
                }
              }
            }
          }
        }
      }
    }
  `);

  return useKisSectionData(data);
};
