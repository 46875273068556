import { useStaticQuery, graphql } from 'gatsby';
import { useKisSectionData } from './helper';

export const useKisLandingPageData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: allKisLandingPagePage {
        nodes {
          entry {
            header {
              caption {
                html
              }
              cta_button_text
              cta_button_to
              headline {
                html
              }
            }
            pricing {
              description
              headline {
                html
              }
              list_items {
                text
              }
              list_title
            }
            product {
              button_text
              button_to
              data_four_title
              data_four_value
              data_one_value
              data_one_title
              data_three_title
              data_three_value
              data_two_title
              data_two_value
              headline {
                html
              }
              image {
                alt
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            features {
              description {
                html
              }
              display_number
              headline {
                html
              }
              second_headline {
                html
              }
              step_one_caption {
                html
              }
              step_one_description {
                html
              }
              step_one_title {
                html
              }
              step_three_description {
                html
              }
              step_three_title {
                html
              }
              step_two_description {
                html
              }
              step_two_title {
                html
              }
            }
            story {
              button_text
              button_to
              description {
                html
              }
              headline {
                html
              }
            }
            storytwo {
              button_text
              button_to
              description {
                html
              }
              headline {
                html
              }
            }
            process {
              process_steps {
                title
                description
              }
            }
            wood {
              caption
              title
            }
          }
        }
      }
    }
  `);

  return useKisSectionData(data);
};
