import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';
import { gsap } from 'gsap';

export const DividerLine = ({
  shortLine = false,
  animated = false,
  isLight = false,
  sx
}) => {
  const lineRef = React.useRef(null);

  React.useEffect(() => {
    if (animated) {
      let tween = gsap.from(lineRef.current, {
        scaleX: 0,
        transformOrigin: 'left center',
        duration: shortLine ? 1 : 2,
        scrollTrigger: {
          trigger: lineRef.current,
          start: 'top 85%'
        }
      });

      return () => {
        tween.kill(true);
        tween.scrollTrigger?.kill(true);
      };
    }
  }, []);

  return (
    <Box
      as="hr"
      ref={lineRef}
      className="divider-line"
      sx={{
        borderWidth: '0.1rem 0 0 0',
        borderColor: isLight ? 'white' : 'text',
        borderStyle: 'solid',
        ...sx
      }}
    />
  );
};
