import React from 'react';
import { Paragraph, Box, GridWrapper } from '@thepuzzlers/pieces';

import { ImageWithListWrapper } from './ImageWithListWrapper';
import { Line } from './Line';

export const ImageWithWrappedList = ({
  data,
  image,
  index,
  totalData,
  wrapperSx,
  textSx,
  imageGridColumn
}) => {
  return (
    <ImageWithListWrapper
      key={`imageWithListData-${index}`}
      imageData={image}
      index={index}
      totalData={totalData}
      wrapperSx={wrapperSx}
      imageGridColumn={imageGridColumn}
      textSx={textSx}>
      <WrappedLinks imageIndex={index} data={data} />
    </ImageWithListWrapper>
  );
};

const WrappedLinks = ({ data, imageIndex }) => {
  let gridRow = 0;

  return (
    <GridWrapper variant="inside.autoColumns">
      {data.description_list.map((desc, listIndex, listArr) => {
        if (listIndex % 2 == 0) gridRow += 1;

        return (
          <ListItemWithStripes
            key={`list-item-${listIndex}`}
            text={desc.description}
            index={listIndex}
            imageIndex={imageIndex}
            listItemIndex={listIndex}
            isFirstRow={listIndex === 0 || listIndex === 1}
            gridRow={gridRow}
            isEven={listIndex % 2 == 0}
          />
        );
      })}
    </GridWrapper>
  );
};

const ListItemWithStripes = ({
  text,
  imageIndex,
  listItemIndex,
  isFirstRow,
  gridRow,
  isEven
}) => (
  <Box
    sx={{
      gridColumnEnd: isEven
        ? [
            'span 10',
            'span 5',
            'span 9',
            imageIndex === 0 ? 'span 9' : 'span 7',
            'span 6',
            'span 5'
          ]
        : [
            'span 10',
            'span 7',
            'span 9',
            imageIndex === 0 ? 'span 9' : 'span 7',
            'span 6',
            'span 5'
          ],
      gridRow: [
        'auto',
        gridRow,
        gridRow,
        imageIndex === 0 ? 'auto' : gridRow, //the first image item is not wrapped
        gridRow,
        gridRow
      ],
      display: 'flex',
      alignItems: 'center',
      alignSelf: 'end',
      marginTop: [
        listItemIndex !== 0 ? '2rem' : 0,
        !isFirstRow && '2.4rem',
        !isFirstRow && '1.4rem',
        (imageIndex === 0 && listItemIndex === 0) ||
        (imageIndex !== 0 && isFirstRow)
          ? '0'
          : '1.2rem',
        !isFirstRow && '1.4rem',
        !isFirstRow && '1.2rem'
      ]
    }}>
    <Line />
    <Paragraph
      as="p"
      sx={{
        fontSizes: ['1.6rem', '1.8rem', '1.8rem', '1.6rem', '1.8rem', '1.8rem'],
        fontFamily: 'body.bold',
        lineHeight: 1.25
      }}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  </Box>
);
