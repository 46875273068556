import { useStaticQuery, graphql } from 'gatsby';

export const useSiteData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: allSite {
        nodes {
          siteMetadata {
            url
          }
        }
      }
    }
  `);

  return data;
};
