import React from 'react';
import gsap from 'gsap';
import { Box } from '@thepuzzlers/pieces';

export const ProgressBar = () => {
  const progressRef = React.useRef();

  React.useEffect(() => {
    gsap.to(progressRef.current, {
      scrollTrigger: progressRef.current,
      width: '100%',
      duration: 2,
      ease: 'power4'
    });
  }, []);
  return (
    <Box
      ref={progressRef}
      sx={{
        bg: 'secondary',
        height: '0.4rem',
        gridColumn: '1/span 24',
        width: '0%'
      }}
    />
  );
};
