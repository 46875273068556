module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-thepuzzlers-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/vercel/path0/src/intl","secondPath":"/vercel/path0/src/intl","languages":["de"],"locales":{"default":"de","translations":[]},"translatedPaths":[{"default":"privacy-policy","de":"datenschutz"},{"default":"legal","de":"impressum"},{"default":"houses","de":"modulhaeuser"}],"redirect":false,"wrapProvider":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"3","matomoUrl":"https://the-puzzlers.matomo.cloud","siteUrl":"https://www.mdl-haus.de","disableCookies":true,"respectDnt":false},
    },{
      plugin: require('../node_modules/gatsby-source-keepitsimple/gatsby-browser.js'),
      options: {"plugins":[],"prefix":"mdl-haus"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
