export const getTwoDigitAndConvertToString = (number) => {
  if (number >= 10) return `${number}`;
  return `0${number}`;
};

export const getNumbersArray = (number) => {
  const outputRangeLength = 20;
  const slowNumberRange = 5; //start and end dont jump the number

  let numbersArray = [];

  // If the number is below 20
  if (number < outputRangeLength) {
    for (let i = 1; i <= number; i++) {
      numbersArray.push(i);
    }
    return numbersArray;
  }

  // if the number is more than 20
  const fastNumberRange = number - slowNumberRange * 2; // fast is middle number, so subtract it with first and end slow number range
  const numberJump = Math.ceil(
    fastNumberRange / (outputRangeLength - 2 * slowNumberRange)
  );

  // fill the first slow number
  for (let i = 1; i <= slowNumberRange; i++) {
    numbersArray.push(i);
  }

  // fill the middle fast number
  for (
    let i = numbersArray[numbersArray.length - 1] + 1;
    i < number - slowNumberRange;
    i += numberJump
  ) {
    numbersArray.push(i);
  }

  // fill the last slow number
  for (let i = numbersArray[numbersArray.length - 1] + 1; i <= number; i++) {
    numbersArray.push(i);
  }

  return numbersArray.map((number) => getTwoDigitAndConvertToString(number));
};
