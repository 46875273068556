import React from 'react';

// Pieces imports
import { Button } from '@thepuzzlers/pieces';

export const PrimaryButton = ({
  children,
  light = false,
  sx = {},
  ...props
}) => (
  <Button sx={sx} variant={light ? 'primaryLight' : 'primary'} {...props}>
    <span>{children}</span>
  </Button>
);
