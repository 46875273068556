import React from 'react';

import { Box } from '@thepuzzlers/pieces';

export const BannerDecoration = ({ sx, ...props }) => {
  return (
    <Box
      className="__banner-decoration"
      as="svg"
      viewBox="0 0 386 145"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        color: 'darkForrest',
        width: '100%',
        ...sx
      }}
      {...props}>
      <g clip-path="url(#clip0_972_1839)">
        <path
          d="M245.585 41.8823C169.966 -21.1337 106.95 -48.8608 28.8096 -76.5879L-9 21.7172C-9 21.7172 69.1399 39.3617 164.924 99.8571C316.163 195.376 298.518 266.22 275.833 301.509C376.658 235.972 334.681 116.129 245.585 41.8823Z"
          fill="currentColor"
        />
        <path
          d="M336.328 16.6767C301.039 -43.8188 230.461 -101.794 182.569 -127L137.197 -56.422C137.197 -56.422 191.545 -31.2156 248.106 19.1973C364.055 122.544 349.771 198.163 338.849 233.452C376.658 177.998 378.928 89.7048 336.328 16.6767Z"
          fill="currentColor"
        />
        <path
          d="M369.097 -3.48772C350.948 -57.9336 323.725 -91.7102 303.56 -114.396L275.833 -81.6276C275.833 -81.6276 316.163 -46.3386 341.37 -3.48772C377.382 57.7326 380.02 123.385 376.659 145.23C384.221 125.065 388.422 54.487 369.097 -3.48772Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_972_1839">
          <rect width="386" height="145" fill="white" />
        </clipPath>
      </defs>
    </Box>
  );
};
