import React from 'react';
import Lottie from 'lottie-react';

// pieces
import { Box } from '@thepuzzlers/pieces';

export const LottieContainer = ({ containerRef, lottieRef, lottie, sx }) => (
  <Box ref={containerRef} sx={sx}>
    <Lottie
      lottieRef={lottieRef}
      height="100%"
      animationData={lottie}
      loop={false}
      autoplay={false}
    />
  </Box>
);
