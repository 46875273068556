import React from 'react';

import { navigate } from 'gatsby';

// External Components
import {
  Overlay,
  Box,
  GridWrapper,
  FlexWrapper,
  Link
} from '@thepuzzlers/pieces';
import { NavigationLink } from 'gatsby-theme-thepuzzlers-intl';

// Local Components
import { GridSpacer, PrimaryButtonSmall } from 'components';

// Self Components
import { NavigationBar } from '../components';
import Pattern from 'assets/patterns/pattern-bg.png';

// Animations
import {
  slideBackground,
  staggerElements,
  linksReveal,
  elementReveal
} from './animations';

// helper
import { usePathMatch } from '../helper';

export const NavigationOverlay = ({
  handleClose,
  pageLinks,
  contactLinks,
  button
}) => {
  return (
    <Overlay
      handleClose={handleClose}
      shouldCloseOnBackdropClick
      shouldCloseOnEscapePress
      containerSx={{
        overflow: 'hidden'
      }}>
      <Content
        handleClose={handleClose}
        pageLinks={pageLinks}
        contactLinks={contactLinks}
        button={button}
      />
    </Overlay>
  );
};

const Content = ({ handleClose, pageLinks, contactLinks, button }) => {
  return (
    <Box
      // Animation values
      initial="initial"
      animate="animate"
      exit="exit"
      sx={{ height: '100%', overflowY: 'auto' }}>
      <NavigationBar
        handleMenuButtonClick={handleClose}
        isOnOverlay
        handleContactButtonClick={handleClose}
      />
      <OverlayBody
        pageLinks={pageLinks}
        contactLinks={contactLinks}
        handleClose={handleClose}
        button={button}
      />
      <Background />
    </Box>
  );
};

const OverlayBody = ({ pageLinks, contactLinks, handleClose, button }) => {
  return (
    <GridWrapper
      sx={{
        position: 'relative',
        zIndex: 1,
        height: [null, null, '100%', 'auto', 'auto', null],
        minHeight: [null, null, '100%', '100%', '100%', null],
        gridTemplateRows: [
          null,
          null,
          '1fr auto 1fr auto',
          '1fr auto 1fr auto',
          '1fr auto 1fr auto',
          null
        ]
      }}>
      <LinksWrapper pageLinks={pageLinks} handleClose={handleClose} />
      {/* <LanguageSwitch data={language} handleClose={handleClose} /> */}
      <ContactButton data={button} handleClose={handleClose} />
      <Contacts contactLinks={contactLinks} />
      <BottomSpacer />
    </GridWrapper>
  );
};

const LinksWrapper = ({ pageLinks, handleClose }) => {
  return (
    <FlexWrapper
      // Animation value
      variants={staggerElements}
      sx={{
        gridColumn: ['1/13', '1/13', '1/25', '3 / span 20'],
        mt: ['7.1rem', '7.9rem', '7.5rem', '6.4rem', '17.6rem', null],
        flexDirection: ['column', 'column', 'column', 'row'],
        flexWrap: [null, null, null, 'wrap'],
        justifyContent: 'center',
        gap: ['4.9rem', '6rem', '8.2rem', '3.3rem 6.4rem', '6.1rem 6.4rem'],
        gridRow: [null, null, 2, 2, 2, null]
      }}>
      {pageLinks.map((link, index) => (
        <LinkItem key={index} data={link} handleClose={handleClose} />
      ))}
    </FlexWrapper>
  );
};

const LinkItem = ({ data: { text, to }, handleClose }) => {
  const { isPathMatch, pathname } = usePathMatch(to);

  // set the path ref
  const pathRef = React.useRef(null);

  // This Effect make the overlay closed only when the page is succesfully changed
  React.useEffect(() => {
    // set the initial path
    if (!pathRef.current) {
      pathRef.current = pathname;
    }
    // change path if current path is not same with path provided by roter
    if (pathRef.current !== pathname) {
      pathRef.current = pathname;
      handleClose();
    }
  }, [pathname]);
  return (
    // Extra box for animation purposes
    <Box
      // Animation value
      variants={linksReveal}>
      <NavigationLink
        to={to}
        sx={{
          fontFamily: 'body.bold',
          color: isPathMatch ? 'secondary' : 'white',
          lineHeight: 1,
          fontSize: ['2.2rem', '2.8rem', '3.6rem', '2.2rem', '3.6rem', null],
          borderLeft: isPathMatch
            ? ({ colors }) => `0.3rem solid ${colors.secondary}`
            : 'none',
          pl: isPathMatch ? '0.8rem' : 'none'
        }}>
        {text}
      </NavigationLink>
    </Box>
  );
};

const ContactButton = ({ data: { text, to }, handleClose }) => {
  const { pathname } = usePathMatch(to);

  // set the path ref
  const pathRef = React.useRef(null);

  // This Effect make the overlay closed only when the page is succesfully changed
  React.useEffect(() => {
    // set the initial path
    if (!pathRef.current) {
      pathRef.current = pathname;
    }
    // change path if current path is not same with path provided by roter
    if (pathRef.current !== pathname) {
      pathRef.current = pathname;
      handleClose();
    }
  }, [pathname]);
  return (
    <PrimaryButtonSmall
      onClick={() => {
        navigate(to);
        // handleClose();
      }}
      custom={0.3}
      variants={elementReveal}
      light
      sx={{
        display: ['block', 'block', 'none', 'none', 'none', 'none'],
        zIndex: 1,
        gridColumn: ['1 / span 6', '1 / span 6', null, null, null, null],
        fontFamily: 'body.bold',
        justifySelf: 'start',
        mt: ['5rem', '6.4rem', null, null, null, null]
      }}>
      {text}
    </PrimaryButtonSmall>
  );
};

const Contacts = ({ contactLinks }) => {
  return (
    <FlexWrapper
      // Animation values
      custom={0.3}
      variants={elementReveal}
      sx={{
        gridColumn: ['1/ span 11', '1/13', '1/25', '2/ span 22', '2/ span 22'],
        flexDirection: ['column', 'column', 'column', 'row', 'row', null],
        alignItems: 'center',
        justifyContent: [
          null,
          null,
          null,
          'space-between',
          'space-between',
          null
        ],
        justifySelf: ['end', 'end', 'end', 'auto', 'auto', null],
        mt: ['12.4rem', '8rem', '15.9rem', '7.1rem', '23.5rem'],
        gap: ['0.8rem', '0.8rem', '0.8rem', 'auto', 'auto'],
        gridRow: [null, null, 4, 4, 4, null]
      }}>
      {contactLinks.map((link, index) => (
        <ContactLinkItem key={index} data={link} />
      ))}
    </FlexWrapper>
  );
};

const ContactLinkItem = ({ data: { text, to } }) => {
  return (
    <Link
      href={to}
      sx={{
        fontFamily: 'primary.normal',
        lineHeight: 1.5,
        fontSize: ['2rem', '2.5rem', '3rem', '2.2rem', '3rem'],
        color: 'white'
      }}>
      {text}
    </Link>
  );
};

const Background = () => (
  <Box
    variants={slideBackground}
    sx={{
      background: ({ colors }) => `url(${Pattern}),${colors.primary}`,
      backgroundSize: 'cover',
      height: '100%',
      overflowY: 'auto',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%'
    }}
  />
);

export const BottomSpacer = () => (
  <GridSpacer
    height={['4.5rem', '9rem', '6.2rem', '2.5rem']}
    sx={{
      gridRow: [null, null, 5, 5, 5, null]
    }}
  />
);
