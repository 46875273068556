import React from 'react';

// Pieces components
import { Paragraph, Box } from '@thepuzzlers/pieces';

// Local components
import { ProgressBar } from '../components/ProgressBar';

export const SmallListItem = ({
  text,
  sx,
  color = 'darkText',
  variant = 'normal',
  ...props
}) => (
  <Box sx={sx}>
    <ProgressBar />
    <Paragraph
      as="p"
      dangerouslySetInnerHTML={{
        __html: text
      }}
      sx={{
        color,
        mt: ['1rem', '1.2rem', '1.2rem', '1rem', '1.2rem', '1rem'],
        lineHeight: 1.25,
        fontFamily: `body.${variant}`,
        fontSize: ['1.6rem', '1.8rem', '1.8rem', '1.6rem', '1.8rem', '1.8rem']
      }}
      {...props}
    />
  </Box>
);
