import React from 'react';

// Pieces imports
import { Button } from '@thepuzzlers/pieces';

export const PrimaryButtonSmall = ({
  designatedRef,
  children,
  light = false,
  sx = {},
  ...props
}) => (
  <Button
    ref={designatedRef}
    sx={{
      display: 'flex',
      alignItems: 'center',
      ...sx
    }}
    variant={light ? 'primaryLightSmall' : 'primarySmall'}
    {...props}>
    <span>{children}</span>
  </Button>
);
