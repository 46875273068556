import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const MdlHausLogo = ({ sx, ...props }) => {
  return (
    <Box
      as="svg"
      viewBox="0 0 179 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: '100%',
        ...sx
      }}
      {...props}>
      <g clipPath="url(#clip0_470_1128)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M178.113 3.81198H177.468L176.513 2.77887H176.045V3.81198H175.56V1.16297H176.463C176.613 1.16051 176.764 1.16759 176.913 1.18416C177.034 1.20111 177.151 1.23969 177.258 1.29807C177.365 1.35376 177.456 1.4369 177.521 1.53913C177.582 1.64625 177.611 1.76837 177.605 1.89145C177.612 2.06367 177.553 2.232 177.442 2.36297C177.319 2.49758 177.164 2.59948 176.992 2.65966L178.113 3.81198ZM177.097 1.90999C177.099 1.84563 177.084 1.78192 177.055 1.72456C177.025 1.66723 176.978 1.62024 176.921 1.58946C176.861 1.55767 176.796 1.53618 176.729 1.52588C176.641 1.52069 176.553 1.52069 176.466 1.52588H176.037V2.4239H176.403C176.507 2.42581 176.611 2.41694 176.713 2.39741C176.791 2.38308 176.864 2.35039 176.926 2.30205C176.981 2.26109 177.025 2.20629 177.053 2.1431C177.083 2.06479 177.097 1.98077 177.092 1.89675L177.097 1.90999Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M179 2.51661C179.002 3.101 178.802 3.66787 178.435 4.12054C178.068 4.5732 177.556 4.88362 176.987 4.99885C176.417 5.11407 175.826 5.02696 175.314 4.75237C174.801 4.47779 174.399 4.03274 174.176 3.49313C173.953 2.95353 173.923 2.3528 174.091 1.79341C174.259 1.23402 174.615 0.750624 175.097 0.42567C175.58 0.100717 176.16 -0.0456635 176.737 0.0115001C177.315 0.0686637 177.855 0.325827 178.266 0.739128C178.502 0.969318 178.689 1.24576 178.816 1.55145C178.942 1.85713 179.005 2.18559 179 2.51661ZM178.663 2.51661C178.667 2.22892 178.613 1.94338 178.504 1.67741C178.395 1.41144 178.234 1.17061 178.029 0.969595C177.829 0.764953 177.591 0.602404 177.328 0.491443C177.066 0.380482 176.784 0.323333 176.499 0.323333C176.214 0.323333 175.932 0.380482 175.669 0.491443C175.406 0.602404 175.168 0.764953 174.968 0.969595C174.562 1.38106 174.334 1.93771 174.334 2.51794C174.334 3.09817 174.562 3.65482 174.968 4.06629C175.168 4.27093 175.406 4.43347 175.669 4.54443C175.932 4.65539 176.214 4.71254 176.499 4.71254C176.784 4.71254 177.066 4.65539 177.328 4.54443C177.591 4.43347 177.829 4.27093 178.029 4.06629C178.234 3.86496 178.396 3.62369 178.505 3.35724C178.614 3.09079 178.668 2.80475 178.663 2.51661Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.8304 19.9869H0V18.5404H7.08736V10.4212L14.609 3.80397L17.954 6.63576V4.9298L13.9326 1.50464L5.7399 8.73378V16.8503H0V7.51275L0.0131589 7.04388L0.057899 6.575L0.131589 6.11142L0.234228 5.6505L0.363185 5.20017L0.521091 4.75778L0.707947 4.32599L0.92112 3.90745L1.15798 3.50215L1.42116 3.11275L1.70539 2.74189L2.01594 2.38957L2.34491 2.0558L2.69493 1.74586L3.06338 1.45712L3.45289 1.19222L3.85292 0.953809L4.26873 0.739239L4.69771 0.553809L5.13722 0.394868L5.58725 0.262418L6.04255 0.161756L6.50311 0.0875835L6.96893 0.0451994L7.43476 0.0293054H19.8304V19.9869ZM17.1778 9.40418C16.5774 9.75449 15.9412 10.0387 15.2803 10.2519C15.0992 10.3065 14.909 10.358 14.7093 10.4121C13.8529 10.6442 12.823 10.9234 11.5958 11.6903C10.0826 12.636 9.49041 15.1578 9.46673 15.5393C9.46673 15.5393 12.4617 14.1962 13.9539 13.0519C14.8371 12.3804 15.6717 11.6466 16.4514 10.8558C15.5195 12.0747 15.2041 12.3447 14.9995 12.5198C14.9435 12.5678 14.8958 12.6086 14.8461 12.6598C13.8219 13.5714 12.7349 14.4088 11.5932 15.1658C11.0984 15.4969 7.84556 17.4572 7.84556 17.4572V18.5406C7.84556 18.5406 9.87465 17.2161 10.8063 16.9035C10.8755 16.88 10.9552 16.8534 11.0437 16.8237C12.0738 16.479 14.3066 15.7318 15.225 14.8903C16.2593 13.9366 16.8804 12.5803 17.2252 11.4466C17.57 10.3128 17.9489 8.88234 17.9489 8.88234L17.1778 9.40418Z"
          fill="currentColor"
        />
        <path
          d="M30.6364 19.9949H24.7492V0.0345951H33.6867L40.7556 12.5008L47.9482 0.0345951H56.7647V19.9949H50.9037V5.77235L42.7136 19.9949H38.7581L30.6364 5.77235V19.9949Z"
          fill="currentColor"
        />
        <path
          d="M160.259 16.7922V8.7869L162.222 8.77631V16.7498C162.222 18.9096 160.424 19.9896 156.827 19.9896H153.474C149.85 19.9878 148.037 18.9079 148.037 16.7498V8.77631H149.937V16.7498C149.936 17.7794 151.005 18.2942 153.145 18.2942H157.133C159.22 18.2942 160.259 17.7935 160.259 16.7922Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M142.287 19.9894H133.183C131.817 19.9894 130.707 18.5907 130.707 16.8715V15.7722C130.707 14.053 131.817 12.6543 133.183 12.6543H143.295V11.9073C143.295 11.3166 142.91 10.8477 142.421 10.8477H131.13V8.7841H142.421C144.066 8.7841 144.897 9.53907 144.897 11.0278V17.8172C144.895 18.3568 144.709 18.8792 144.371 19.298C143.784 19.9391 142.626 19.9894 142.287 19.9894ZM133.183 14.6914C132.702 14.6914 132.309 15.1735 132.309 15.7669V16.8662C132.309 17.457 132.694 17.9258 133.183 17.9258H143.295V14.694L133.183 14.6914Z"
          fill="currentColor"
        />
        <path
          d="M165.383 17.9311V20L176.534 20.0027C177.871 20.0027 178.96 18.6013 178.96 16.8795V16.4848C178.96 14.7629 177.871 13.3616 176.534 13.3616H167.394C166.899 13.3616 166.512 12.8927 166.512 12.302V11.9047C166.512 11.3086 166.899 10.8451 167.394 10.8451H178.974V8.77883H167.394C166.054 8.77883 164.965 10.1802 164.965 11.8994V12.2967C164.965 14.0186 166.054 15.4172 167.394 15.4172H176.534C177.018 15.4172 177.413 15.8941 177.413 16.4768V16.8715C177.413 17.4676 177.026 17.9311 176.534 17.9311H165.383Z"
          fill="currentColor"
        />
        <path
          d="M125.672 12.6359V19.9949L127.565 20.0055V12.6094C127.565 11.2319 127.18 10.2544 126.391 9.6955C125.567 9.09948 124.106 8.79749 122.056 8.79749H116.003V5.20543H114.108V19.9949H116.003V10.7975H122.572C123.638 10.7975 124.356 10.9114 124.883 11.179C125.409 11.4465 125.672 11.9366 125.672 12.6359Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M78.256 0.0345951C81.2826 0.0345951 83.4836 0.564396 84.8591 1.624C86.2347 2.6836 86.9224 4.38426 86.9224 6.72598V13.6134C86.9224 15.828 86.3724 17.4474 85.2723 18.4717C84.1722 19.496 82.4239 20.0072 80.0272 20.0055H60.8653V0.0345951H78.256ZM66.9131 4.9538V15.1419H77.177C78.4613 15.1419 79.4017 14.877 79.9983 14.3472C80.5948 13.8174 80.8922 12.9909 80.8904 11.8677V8.36042C80.8904 7.18956 80.5281 6.32951 79.8035 5.78029C79.0789 5.23106 77.9498 4.95556 76.4164 4.9538H66.9131Z"
          fill="currentColor"
        />
        <path
          d="M110.132 15.1684H96.9363V0.0345951H90.8779V19.9949H110.132V15.1684Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_470_1128">
          <rect width="179" height="20" fill="white" />
        </clipPath>
      </defs>
    </Box>
  );
};
