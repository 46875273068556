export const fontFamilies = {
  body: {
    normal: 'compiler-plain-normal-regular, sans-serif',
    medium: 'compiler_plainmedium, sans-serif',
    bold: 'compiler-plain-normal-bold, sans-serif'
  },
  primary: {
    normal: 'freigeistregular, serif',
    bold: 'freigeistbold, serif'
  },
  primaryBold: {
    normal: 'freigeistbold, serif'
  },
  bodyBold: {
    normal: 'compiler-plain-normal-bold, sans-serif'
  }
};
