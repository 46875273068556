import React from 'react';

// External Components
import { NavigationLink } from 'gatsby-theme-thepuzzlers-intl';

export const Hyperlink = ({ variant = 'primary', text, to, sx }) => {
  return (
    <NavigationLink to={to} variant={`hyperlink.${variant}`} sx={sx}>
      {text}
    </NavigationLink>
  );
};
