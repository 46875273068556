import React from 'react';

// External components
import { GridWrapper, Button, Box, FlexWrapper } from '@thepuzzlers/pieces';
import {
  NavigationLink,
  LanguageSwitchLink
} from 'gatsby-theme-thepuzzlers-intl';
import { navigate } from 'gatsby';

// Local Components
import {
  MdlHausLogo,
  MenuClosed,
  PrimaryButtonSmall,
  MenuOpen
} from 'components';

// Data
import { useNavigationData } from '../useNavigationData';

// Animation
import { onScrollNavigationBarAnimation } from '../animation';

// Assets

// Helper function
import { usePathMatch } from '../helper';

export const NavigationBar = ({
  handleMenuButtonClick,
  handleContactButtonClick,
  isOnOverlay,
  sx
}) => {
  const { pageLinks, button } = useNavigationData();

  // use hide animation for navigation bar except in it is used in overlay
  React.useEffect(() => {
    !isOnOverlay && onScrollNavigationBarAnimation();
  }, [isOnOverlay]);

  return (
    // Markup
    <ContentWrapper sx={sx}>
      <Logo isOnOverlay={isOnOverlay} />
      <Navlinks data={pageLinks} />
      <ContactButton
        data={button}
        isOnOverlay={isOnOverlay}
        handleClick={handleContactButtonClick}
      />
      <MenuButton onClick={handleMenuButtonClick} isOnOverlay={isOnOverlay} />
    </ContentWrapper>
  );
};

// Elements

const ContentWrapper = ({ children, sx }) => (
  <GridWrapper
    as="nav"
    className="navigation-bar-wrapper"
    sx={{
      position: 'sticky',
      py: ['1.5rem', '2.4rem', '2rem', '1.5rem', '1.7rem', '1.6rem'],
      top: 0,
      left: 0,
      width: '100%',
      zIndex: 10,
      ...sx
    }}>
    {children}
  </GridWrapper>
);

const Logo = ({ isOnOverlay }) => (
  <NavigationLink
    to="/"
    sx={{
      alignSelf: 'center',
      gridColumnStart: 1,
      width: ['16.2rem', '19.8rem', '19.8rem', '17.9rem', '19.8rem', '22.5rem'],
      display: 'flex',
      alignItems: 'center',
      '& a': {
        display: 'flex',
        alignItems: 'center'
      }
    }}>
    <MdlHausLogo
      className="company-logo"
      sx={{ color: isOnOverlay ? 'white' : 'primary', width: '100%' }}
    />
  </NavigationLink>
);

const ContactButton = ({ data: { text, to }, isOnOverlay, handleClick }) => {
  const { pathname } = usePathMatch(to);

  // set the path ref
  const pathRef = React.useRef(null);

  // This Effect make the overlay closed only when the page is succesfully changed
  React.useEffect(() => {
    if (isOnOverlay) {
      // set the initial path
      if (!pathRef.current) {
        pathRef.current = pathname;
      }
      // change path if current path is not same with path provided by roter
      if (pathRef.current !== pathname) {
        pathRef.current = pathname;
        handleClick();
      }
    }
  }, [pathname]);

  return (
    <PrimaryButtonSmall
      onClick={() => {
        navigate(to);
      }}
      light={isOnOverlay ? true : false}
      sx={{
        display: ['none', 'none', 'block', 'block', 'block', 'block'],
        gridColumnStart: [null, null, 17, 18, 19, 22],
        mr: 'auto',
        zIndex: 1,
        color: []
      }}>
      {text}
    </PrimaryButtonSmall>
  );
};

const MenuButton = ({ onClick, isOnOverlay }) => (
  <Button
    className="navigation__menu-button"
    variant="clear"
    onClick={onClick}
    sx={{
      alignSelf: 'center',
      color: 'text',
      display: ['flex', 'flex', 'flex', 'flex', 'flex', 'none'],
      gridColumnEnd: [13, 13, 25, 25, 25, null],
      justifySelf: 'end',
      width: ['2.8rem', '3.4rem', '3.4rem', '2.8rem', '3.4rem', null]
    }}>
    {isOnOverlay ? <MenuOpen /> : <MenuClosed />}
  </Button>
);

const Navlinks = ({ data }) => (
  <FlexWrapper
    className="navigation__nav-links"
    sx={{
      alignSelf: 'center',
      display: ['none', 'none', 'none', 'none', 'none', 'flex'],
      gridColumn: [null, null, null, null, null, '7/span 14'],
      justifySelf: [null, null, null, null, null, 'end']
    }}>
    {data.map((link, index) => (
      <NavigationLinkItem key={index} link={link} />
    ))}
  </FlexWrapper>
);

const NavigationLinkItem = ({
  link: { to, text }
  // this options bellow supposed to be used in navigation header which has header section with different background
}) => {
  const { isPathMatch } = usePathMatch(to);

  return (
    <NavigationLink
      key={to}
      to={to}
      variant="links.clear"
      sx={{
        position: 'relative',
        display: 'block',
        fontFamily: 'body.bold',
        fontSize: [null, null, null, null, null, '1.6rem'], // null because it's not visible in first four breakpoints
        lineHeight: 1,
        whiteSpace: 'nowrap',
        color: 'primary',
        ':not(:first-of-type)': {
          ml: '3.2rem'
        },

        '& .link-indicator': {
          width: isPathMatch ? '100%' : null
        },

        ':hover .link-indicator': {
          width: '100%'
        }
      }}>
      <Box
        className="link-indicator"
        as="span"
        sx={{
          position: 'absolute',
          width: '0%',
          height: '0.5rem',
          bg: 'secondary',
          top: '-0.8rem',
          transition: 'width 0.5s ease'
        }}
      />
      {text}
    </NavigationLink>
  );
};

export const LangSwitchLink = ({ text, language, sx, onClick }) => (
  <LanguageSwitchLink
    variant="buttons.clear"
    sx={{
      fontFamily: 'primary.normal',
      fontSize: ['1.6rem', '1.6rem', '1.8rem', '1.4rem', '1.5rem', '1.5rem'],
      lineHeight: 1,
      textTransform: 'uppercase',
      p: [
        '1.4rem 1rem',
        '1.4rem 1rem',
        '1.5rem 1rem',
        '1.1rem 1rem',
        '1.6rem 1.2rem',
        '1.6rem 1.2rem'
      ],
      position: 'relative',
      zIndex: 1,
      '&:hover, &.active ': {
        bg: 'secondary'
      },
      ...sx
    }}
    language={language}
    onClick={onClick}>
    {text}
  </LanguageSwitchLink>
);
