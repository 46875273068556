import React, { forwardRef } from 'react';
import gsap from 'gsap';
import { Heading, Paragraph, Box, GridWrapper } from '@thepuzzlers/pieces';

export const NumberedList = ({ data, shortHeadline = false }) => {
  return data.map(({ title, description }, i) => (
    <NumberedListItem
      key={`numbered-list-item-${i}`}
      index={i}
      totalData={data.length}
      title={title}
      content={description}
      shortHeadline={shortHeadline}
    />
  ));
};

const NumberedListItem = ({
  index,
  totalData,
  title,
  content,
  shortHeadline
}) => {
  const isLastData = index === totalData - 1;
  const itemRef = React.useRef();

  React.useEffect(() => {
    // Fade in green line
    const greenLine = gsap
      .timeline({
        scrollTrigger: {
          trigger: itemRef.current,
          start: 'top 75%'
        }
      })
      .from(itemRef.current, {
        width: 0,
        ease: 'power2'
      });

    return () => {
      greenLine.scrollTrigger.kill(true);
      greenLine.pause(0).kill(true);
    };
  }, []);

  return (
    <GridWrapper
      sx={{
        gridColumn: [
          '1 / span 12',
          '2 / span 10',
          '4 / span 18',
          '1 / span 24',
          '2 / span 22',
          '3 / span 20'
        ]
      }}
      variant="inside.autoColumns">
      <Line ref={itemRef} />
      <Number index={index} />
      <ItemHeading heading={title} shortHeadline={shortHeadline} />
      <Paragraph
        sx={{
          gridColumn: [
            '1 / span 11',
            '1 / span 9',
            '1 / span 14',
            '15 / span 10',
            '14 / span 9',
            '14 / span 7'
          ],
          gridRow: ['4 / span 1', null, null, '2 / span 1', null, null],
          marginTop: [null, null, null, '3.5rem', null, '3.5rem'],
          marginBottom: [
            isLastData ? '0' : '6.4rem',
            isLastData ? '0' : '8.0rem',
            isLastData ? '0' : '10.0rem',
            isLastData ? '0' : '10.0rem',
            null,
            null
          ],
          fontFamily: 'body.normal',
          lineHeight: 1.5,
          fontSize: ['1.6rem', '1.8rem', '1.8rem', '1.6rem', '1.8rem', '1.8rem']
        }}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </GridWrapper>
  );
};

const Line = forwardRef((props, ref) => (
  <Box
    ref={ref}
    className="greenLineListItem"
    sx={{
      gridColumn: [
        '1 / span 12',
        '1 / span 10',
        '1 / span 18',
        '1 / span 24',
        '1 / span 22',
        '1 / span 20'
      ],
      bg: 'secondary',
      height: '0.3rem'
    }}
  />
));

const Number = ({ index }) => (
  <Heading
    sx={{
      gridColumn: [
        '1 / span 3',
        '1 / span 2',
        '1 / span 4',
        '1 / span 3',
        '1 / span 3',
        null
      ],
      gridRow: '2 / span 1',
      margin: [
        '1.2rem 0 0 0',
        '1.6rem 0 0 0',
        '1.8rem 0 0 0',
        '3.9rem 0 0 0',
        '2.4rem 0 0 0',
        null
      ],
      fontFamily: 'primary.normal',
      color: 'secondary',
      lineHeight: [1.25, 1.25, 1.25, 1, 1.25, 1.25],
      fontSize: ['4.8rem', '5rem', '6.4rem', '4.8rem', '6.4rem', '7.6rem']
    }}
    as="span"
    dangerouslySetInnerHTML={{ __html: `0${index + 1}.` }}
  />
);

const ItemHeading = ({ heading, shortHeadline }) => (
  <Heading
    sx={{
      lineHeight: 1.25,
      gridColumnStart: [1, 1, 1, 5, 5, 5],
      gridColumnEnd: shortHeadline
        ? ['span 9', 'span 7', 'span 12', 'span 9', 'span 7', 'span 7']
        : ['span 11', 'span 9', 'span 14', 'span 9', 'span 7', 'span 7'],
      gridRow: ['3 / span 1', null, null, '2 / span 1', null, null],
      marginTop: [null, null, null, '3.5rem', null, null],
      marginBottom: ['1.2rem', '1.6rem', '1.8rem', 0, null, null],
      fontFamily: 'primary.bold',
      fontSize: ['2.2rem', '2.8rem', '3rem', '2.4rem', '3rem', '3.2rem']
    }}
    as="h4"
    dangerouslySetInnerHTML={{ __html: heading }}
  />
);
