import { graphql, useStaticQuery } from 'gatsby';

export const useFooterData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: footerDataJson {
        title
        hyperlink {
          text
          to
        }
        image {
          src {
            childImageSharp {
              gatsbyImageData(quality: 100)
            }
          }
          alt
        }
        cta {
          title
          seoTitle
          description
        }
        sitemap {
          title
          links {
            text
            to
          }
        }
        socials {
          title
        }
        legal {
          links {
            text
            to
          }
        }
        copyright
        forderverein {
          src {
            childImageSharp {
              gatsbyImageData(placeholder: NONE)
            }
          }
          alt
        }
        award {
          src {
            childImageSharp {
              gatsbyImageData(placeholder: NONE)
            }
          }
          alt
        }
      }
    }
  `);

  return data;
};
