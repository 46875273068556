import React from 'react';

// external Component
import { Box } from '@thepuzzlers/pieces';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
// LocalComponents
import { LinearGradient } from './LinearGradient';

export const ImageWithLinearGradient = ({
  className,
  src,
  alt,
  imgStyle,
  sx,
  imgProps,
  imgSx,
  isToBottom,
  ...props
}) => (
  <Box
    className={className}
    sx={{
      position: 'relative',
      ...sx
    }}
    {...props}>
    <GatsbyImage
      className="gatsby-image"
      {...imgProps}
      image={getImage(src)}
      alt={alt}
      style={{ height: '100%', width: '100%', ...imgSx }}
      imgStyle={imgStyle}
    />
    <LinearGradient isToBottom={isToBottom} />
  </Box>
);
