import React from 'react';

// Pieces components
import { GridItem, Heading, Paragraph } from '@thepuzzlers/pieces';

// Local components
import { ProgressBar } from '../components/ProgressBar';

export const ListItem = ({ headline, paragraph = null, sx }) => {
  return (
    <GridItem sx={sx}>
      <ProgressBar />
      <Heading
        as="h4"
        dangerouslySetInnerHTML={{
          __html: headline
        }}
        sx={{
          mt: '1.2rem',
          fontFamily: 'primary.bold',
          lineHeight: 1.6,
          fontSize: ['2.2rem', '2.8rem', '3rem', '2.2rem', '2.8rem', '3.2rem']
        }}
      />
      <Paragraph
        dangerouslySetInnerHTML={{
          __html: paragraph
        }}
        sx={{
          mt: '1.2rem',
          mb: '8rem',
          fontFamily: 'body.normal',
          lineHeight: 1.75,
          '& > span': {
            fontFamily: 'body.bold'
          },
          fontSize: ['1.6rem', '1.8rem', '1.8rem', '1.6rem', '1.8rem', '1.8rem']
        }}
      />
    </GridItem>
  );
};
