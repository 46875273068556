import { useStaticQuery, graphql } from 'gatsby';
import { useKisSectionData } from './helper';

export const useKisNachhaltigkeitPageData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: allKisNachhaltigkeitPagePage {
        nodes {
          entry {
            comparison {
              charts {
                percentage
                title
                value
              }
              headline {
                html
              }
            }
            cycle {
              headline {
                html
              }
              images {
                title
                image_alt
                text_one
                text_two
                text_three
              }
            }
            faq {
              headline {
                html
              }
              list {
                description
                title
              }
            }
            header {
              description {
                html
              }
              headline {
                html
              }
              wheel_number
              wheel_caption {
                html
              }
            }
          }
        }
      }
    }
  `);

  return useKisSectionData(data);
};
