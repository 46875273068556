import { useStaticQuery, graphql } from 'gatsby';

export const useKisBanner = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: allKisBanner {
        nodes {
          entry {
            image {
              alt
              image {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
                }
              }
            }
            text
          }
        }
      }
    }
  `);

  return data.nodes[0].entry;
};
