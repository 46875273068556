export const formatSquareMeterWithSuperscript = (text) => {
  const textWithSuperScript = text.replace(
    /m2/g,
    'm<sup style="vertical-align:top; position:relative; top: -0.22em">2</sup>'
  );

  return textWithSuperScript;
};

// fix the sup tag add more height to the element
// https://stackoverflow.com/questions/1530685/hoe-can-i-keep-consistent-line-height-with-superscript-elements
