import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const LinearGradient = ({ isToBottom }) => {
  return (
    <Box
      className="linear-gradient"
      sx={{
        background: isToBottom
          ? 'linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 67.19%)'
          : [
              'linear-gradient(180deg, rgba(0, 0, 0, 0.5) 35.07%, rgba(0, 0, 0, 0) 87.56%)',
              'linear-gradient(90deg, rgba(0, 0, 0, 0.5) 40.06%, rgba(0, 0, 0, 0) 100%)',
              'linear-gradient(90deg, rgba(0, 0, 0, 0.5) 40.06%, rgba(0, 0, 0, 0) 100%)',
              'linear-gradient(90deg, rgba(0, 0, 0, 0.5) 40.06%, rgba(0, 0, 0, 0) 100%)',
              'linear-gradient(90deg, rgba(0, 0, 0, 0.5) 40.06%, rgba(0, 0, 0, 0) 100%)',
              'linear-gradient(90deg, rgba(0, 0, 0, 0.5) 40.06%, rgba(0, 0, 0, 0) 100%)'
            ],
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: 1
      }}
    />
  );
};
