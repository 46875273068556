import { useStaticQuery, graphql } from 'gatsby';

export const useKisBusinessContactData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: kisBusinessContact {
        entry {
          phone {
            link_text
            link_to
          }
          contact_links {
            link_text
            link_to
          }
          social_links {
            link_text
            link_to
          }
        }
      }
    }
  `);

  return data.entry;
};
