import { useStaticQuery, graphql } from 'gatsby';
import { useKisSectionData } from './helper';

export const useKisProduktkatalogPageData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: allKisProductCatalogPagePage {
        nodes {
          entry {
            header {
              title
              subtitle
              short_information {
                label
                text
              }
              image {
                alt
                image {
                  childImageSharp {
                    gatsbyImageData(quality: 100)
                  }
                }
              }
            }
            house_categories {
              bedroom_label
              button_label
              floor_space_label
              living_space_label
              price_label
            }
          }
        }
      }
    }
  `);

  return useKisSectionData(data);
};
