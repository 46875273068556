import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@thepuzzlers/pieces';
import { gsap } from 'gsap';

import { ImageContainer } from './ImageContainer';

export const BorderedImage = ({
  yOffset,
  xOffset,
  image,
  sx = {},
  imageAlt = '',
  imageSx = {},
  borderColor = 'darkText',
  animateOnScroll = true,
  externalImageRef = null
}) => {
  const imageRef = React.useRef();

  React.useEffect(() => {
    if (animateOnScroll) {
      const killAnimation = animateImageOnScroll({
        imageRef: externalImageRef ?? imageRef,
        yOffset,
        xOffset
      });
      return killAnimation;
    }
  }, []);

  return (
    <Box sx={{ position: 'relative', marginBottom: 'auto', ...sx }}>
      <Box
        className={`imageBorder-${imageAlt}`}
        sx={{
          border: 'solid',
          borderColor,
          borderWidth: [2, 3, 3, 3, 3, 3],
          top: `${yOffset}%`,
          left: `${xOffset}%`,
          height: '100%',
          width: '100%',
          position: 'absolute'
        }}
      />
      <ImageContainer
        designatedRef={externalImageRef ?? imageRef}
        src={image}
        imageAlt={imageAlt}
        sx={{ bg: 'background', ...imageSx }}
      />
    </Box>
  );
};

BorderedImage.propTypes = {
  yOffset: PropTypes.number.isRequired,
  xOffset: PropTypes.number.isRequired,
  image: PropTypes.shape().isRequired,
  imageAlt: PropTypes.string,
  sx: PropTypes.shape(),
  imageSx: PropTypes.shape(),
  borderColor: PropTypes.string,
  animateOnScroll: PropTypes.bool,
  externalImageRef: PropTypes.shape()
};

function animateImageOnScroll({ imageRef, yOffset, xOffset }) {
  // Animate image to position
  let timeline = gsap.timeline({
    scrollTrigger: {
      trigger: imageRef.current,
      start: 'top center'
    }
  });
  timeline.from(imageRef.current, {
    yPercent: yOffset,
    xPercent: xOffset,
    ease: 'power2.out',
    duration: 1
  });

  const killAnimation = () => {
    timeline.scrollTrigger.kill(true);
    timeline.pause(0).kill(true);
  };

  return killAnimation;
}
