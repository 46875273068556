export const forms = {
  input: {
    variant: 'typography.body-500.normal',
    background: 'transparent',
    borderBottom: (t) => `3px solid ${t.colors.secondary}`,
    color: 'teal100',
    outline: 'none',
    paddingBottom: '5px',
    color: 'text',
    '::placeholder': {
      color: 'primary',
      opacity: 0.6
    },
    fontFamily: 'body.normal',
    lineHeight: 1.75,
    '& > span': {
      fontFamily: 'body.bold'
    },
    fontSize: ['1.6rem', '1.8rem', '1.8rem', '1.6rem', '1.8rem', '1.8rem']
  }
};
